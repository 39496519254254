import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import type { Stripe } from "stripe";
import { ServiceType } from "src/services/subscription/plan-service-type.enum";
import { OrderService } from "src/services/order/order.service";
import { AuthService } from "src/services/auth/auth.service";
import { GetSubscriptionsBody } from "../../../functions/src/endpoints/subscription/subscription.interface";
import { CheckoutSession } from "src/services/payment/payment.interface";
import { ProductBought } from "../../app/redesign/customer/thank-you/thank-you.page";
import { redesignPages } from "src/services/navigation/paths.const";

import { apiPath, paymentEndpoint } from "../../shared/api-path.const";
import { BackendBody, OrderFees } from "../../shared/backend-api.types";
import { assertNever } from "src/services/utils/enum.utils";
import { ApiService } from "../http/api.service";
import { HttpService } from "../http/http.service";
import { firstValueFrom } from "rxjs";
import { OrderStatus } from "../order/order-status.type";

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    private readonly httpService: HttpService,
    private readonly orderService: OrderService,
  ) {}

  public async checkIfPaymentRequired(
    itemIds: string[],
    serviceType: ServiceType,
  ): Promise<BackendBody["PaymentRequirement"]> {
    return this.apiService.sendPostRequest<
      BackendBody["ValidatePaymentRequirementArgs"],
      BackendBody["PaymentRequirement"]
    >(apiPath.paymentRequirement, { itemIds, serviceType });
  }

  public async checkIfPaymentRequiredForPickupOnly(
    serviceType: ServiceType,
  ): Promise<BackendBody["PaymentRequirement"]> {
    return this.apiService.sendPostRequest<
      BackendBody["ValidatePaymentRequirementForPickupOnlyArgs"],
      BackendBody["PaymentRequirement"]
    >(`${apiPath.paymentRequirement}/${paymentEndpoint.forPickupOnly}`, {
      serviceType,
    });
  }

  public createStripeCheckoutSessionForOrder(
    userId: string,
    pickupOrderId: string,
    deliveryOrderId?: string,
  ): Promise<CheckoutSession> {
    const productBought: ProductBought = "delivery";
    return this.apiService.sendPostRequestWithLoading<BackendBody["OrderCheckoutSessionRequestBody"], CheckoutSession>(
      apiPath.orderCheckoutSession,
      {
        deliveryOrderId: deliveryOrderId,
        pickupOrderId: pickupOrderId,
        successRedirectUrl: `${environment.endpoints.frontendDomain}/${redesignPages.thankYou}/${productBought}`,
        userId,
      },
    );
  }

  public createStripePaymentSessionForSubscription(
    userId: string,
    successRedirectUrl: string,
    stripeSubscriptionId: string,
    subscriptionId: string,
    coupon?: string,
    additionalOrderIdFromKiosk?: string,
  ): Promise<CheckoutSession> {
    return this.apiService.sendPostRequestWithLoading<
      BackendBody["SubscriptionCheckoutSessionRequestBody"],
      CheckoutSession
    >(apiPath.subscriptionCheckoutSession, {
      coupon,
      item: { price: stripeSubscriptionId },
      orderIdFromKiosk: additionalOrderIdFromKiosk,
      subscriptionId,
      successRedirectUrl,
      userId,
    });
  }

  public async getPaymentRequiredReasonDisplayString(
    reason: BackendBody["PaymentRequirement"]["reason"],
    fees: OrderFees,
  ): Promise<string | undefined> {
    switch (reason) {
      case "freeFirstTimePickupAlwaysFree":
      case "freeLocalDeliveriesLimit": {
        const limitString =
          fees.freeLocalDeliveriesPerSeason === 1 ? "1 delivery" : `${fees.freeLocalDeliveriesPerSeason} deliveries`;

        return `You are entitled for ${limitString} per season and our records show that you already scheduled ${await this.getNumberOfLocalDeliveriesForLastSeason()} this season.`;
      }
      case "freeLocalPickupsLimit": {
        const limitString =
          fees.freeLocalPickupsPerSeason === 1 ? "1 pickup" : `${fees.freeLocalPickupsPerSeason} pickups`;

        return `You are entitled for ${limitString} per season and our records show that you already scheduled ${await this.getNumberOfLocalPickupsForLastSeason()} this season.`;
      }
      case "noFees":
      case "freeOrderIncluded":
        return "Included in your plan at no additional cost.";
      case "nationwideAlwaysRequirePayment":
        return "Orders outside of our serviced areas require additional payment.";
      case "outsideUserBaseAlwaysRequirePayment":
        return "This order is outside your base location. Only deliveries in your selected base location are included in your plan.";
      case "subscriptionTypeAlwaysRequirePayment":
        return "Your plan does not provide free orders. If you think that this is a mistake, contact us.";
      case "noActiveSubscription":
      case "noSubscription":
        return "No subscription is linked to your account. If you think that this is a mistake, contact us.";
      default:
        return assertNever(reason);
    }
  }

  public async getSubscriptions(): Promise<unknown> {
    return this.httpService.sendGetRequest(environment.endpoints.getSubscriptions);
  }

  public getPaymentHistory(): Promise<Stripe.Charge[]> {
    return this.httpService.sendGetRequest<Stripe.Charge[]>(environment.endpoints.getPaymentHistory);
  }

  public async getUserStripeSubscriptions(userId: string): Promise<Stripe.Subscription[]> {
    return this.httpService.sendPostRequest<GetSubscriptionsBody, Stripe.Subscription[]>(
      environment.endpoints.getSubscriptionsForAdmin,
      { userId },
    );
  }

  public async setPaymentMethodOnAllSubscriptions(paymentMethodId: string): Promise<void> {
    const activeUser = await this.authService.getActiveUser();
    await this.apiService.sendPutRequest<BackendBody["UpdateAllSubscriptionsArgs"], never>(
      `${apiPath.subscription}/${activeUser.id}`,
      {
        stripePaymentMethodId: paymentMethodId,
      },
    );
  }

  private async getNumberOfLocalDeliveriesForLastSeason(): Promise<number> {
    const pickupsThisSeason = (
      await firstValueFrom(
        this.orderService.getAllOrdersForUserIdThisSeason((await this.authService.getActiveUser()).id),
      )
    )
      .filter((order) => order.serviceType === ServiceType.localDelivery)
      .filter((order) => order.status !== OrderStatus.cancelled);

    return pickupsThisSeason.length;
  }

  private async getNumberOfLocalPickupsForLastSeason(): Promise<number> {
    const pickupsThisSeason = (
      await firstValueFrom(
        this.orderService.getAllOrdersForUserIdThisSeason((await this.authService.getActiveUser()).id),
      )
    )
      .filter((order) => order.serviceType === ServiceType.localPickup)
      .filter((order) => order.status !== OrderStatus.cancelled);

    return pickupsThisSeason.length;
  }
}
