import { Component, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthService } from "src/services/auth/auth.service";
import { User, userToString } from "src/services/user/user.interface";
import { environment } from "@environments/environment";
import { Role } from "src/services/user/role.enum";
import { Referral } from "src/services/referral/referral.interface";
import {
  referralLocalStorageKey,
  referralQueryParamKey,
  ReferralService,
} from "src/services/referral/referral.service";
import { adminPages, manageNotificationsPage } from "src/services/navigation/paths.const";
import { tryParseDate } from "src/services/utils/timestamp.utils";
import { NotificationService } from "src/services/notifications/notifications.service";
import { MessageService } from "../services/message/message.service";
import { NavigationService } from "../services/navigation/navigation.service";
import { ApiService } from "../services/http/api.service";
import { apiPath } from "../shared/api-path.const";
import { HttpService } from "../services/http/http.service";
import { TracingRequestBody } from "../../functions/src/endpoints/tracing/tracing.type";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public appPages = [];
  public selectedIndex = 0;
  public appVersion = "";

  public activeUser: User;
  public activeUserReferral: Referral;
  public showDeleteAccountRequest = false;

  private versionLogged: boolean = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    private readonly httpService: HttpService,
    private readonly messageService: MessageService,
    private readonly navigationService: NavigationService,
    private readonly notificationService: NotificationService,
    private readonly platform: Platform,
    private readonly referralService: ReferralService,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
  ) {
    this.initializeApp();
    this.authService.getActiveUserObservable().subscribe(() => {
      void this.checkMenu();
    });
  }

  public initializeApp(): void {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      await this.notificationService.listenForNotifications();
    });
  }

  public async ngOnInit(): Promise<void> {
    this.appVersion = environment.appVersion;
    await this.storeReferralCoupon();
  }

  private async checkMenu(): Promise<void> {
    this.activeUser = await this.authService.getActiveUser();

    if (!this.activeUser) {
      return;
    }

    if (!this.versionLogged) {
      this.versionLogged = true;
      void this.httpService.sendPostRequest<TracingRequestBody, unknown>(environment.endpoints.traceUserVersion, {
        userId: this.activeUser.id,
        version: this.appVersion,
      });
    }

    const credentialsGivenToAppleForReview = "alexandre.emery+17@live.ca";
    this.showDeleteAccountRequest = this.activeUser.email === credentialsGivenToAppleForReview;

    this.activeUserReferral = await this.referralService.getReferralByUserId(this.activeUser.id);

    switch (this.activeUser.role) {
      case Role.admin:
        this.appPages = [
          {
            title: "Administration",
            children: [
              {
                title: "Items",
                url: "/all-items",
              },
              {
                title: "Orders",
                url: "/all-orders",
              },
              {
                title: "Customers and Staff",
                url: "/all-users",
              },
              {
                title: "QR Codes",
                url: `/${adminPages.manageRacks}`,
              },
              {
                title: "Notifications",
                url: `/${manageNotificationsPage}`,
              },
              {
                title: "Kiosk Mode",
                url: `/${adminPages.manageKioskMode}`,
              },
              {
                title: "Order fees",
                url: `/${adminPages.orderFeesPage}`,
              },
            ],
          },
        ];
        break;

      case Role.driver:
        this.appPages = [
          {
            title: "Management",
            children: [
              {
                title: "Orders",
                url: "/all-orders",
              },
            ],
          },
        ];
        break;

      default:
        break;
    }
  }

  public async logout(): Promise<void> {
    await this.authService.logout(() => location.reload());
  }

  public async requestDeleteAccountRequiredForAppleReviewProcess(): Promise<void> {
    await this.messageService.presentAlert(
      "Are you sure?",
      "",
      "The request to delete your account will be processed shortly.",
      async () => {
        void this.apiService.sendPostRequest(apiPath.userDeletionRequest, { externalId: this.activeUser.id });
        await this.authService.logout();
        await this.navigationService.goToLandingPage();
      },
    );
  }

  private async storeReferralCoupon(): Promise<void> {
    const url = new URL(window.location.href);
    const value = url.searchParams.get(referralQueryParamKey);

    if (value) {
      localStorage.setItem(referralLocalStorageKey, value);
    }
  }

  public readonly userToString = userToString;
  public readonly tryParseDate = tryParseDate;
  protected readonly environment = environment;
}
