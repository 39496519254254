import { Injectable } from "@angular/core";
import { ApiService } from "../http/api.service";
import { OrderFees } from "../../shared/backend-api.types";
import { apiPath } from "../../shared/api-path.const";

@Injectable({
  providedIn: "root",
})
export class OrderFeesService {
  constructor(private readonly apiService: ApiService) {}

  public async getDefaultOrderFees(): Promise<OrderFees> {
    return this.apiService.sendGetRequest<OrderFees, undefined>(apiPath.orderFees, undefined);
  }

  public async getDefaultOrderFeesForUser(userId: string): Promise<OrderFees | undefined> {
    try {
      return await this.apiService.sendGetRequest<OrderFees, undefined>(`${apiPath.orderFees}/${userId}`, undefined);
    } catch (error) {
      return undefined;
    }
  }

  public async removeDefaultOrderFeesForUser(userId: string): Promise<void> {
    await this.apiService.sendDeleteRequest<undefined, undefined>(`${apiPath.orderFees}/${userId}`, undefined);
  }

  public async setDefaultOrderFees(orderFees: OrderFees): Promise<void> {
    await this.apiService.sendPutRequest<OrderFees, void>(apiPath.orderFees, orderFees);
  }

  public async setDefaultOrderFeesForUser(userId: string, orderFees: OrderFees): Promise<void> {
    await this.apiService.sendPutRequest<OrderFees, void>(`${apiPath.orderFees}/${userId}`, orderFees);
  }
}
