import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { environment } from "@environments/environment";
import { objectToQueryString } from "../utils/api.utils";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private readonly httpService: HttpService) {}

  public sendDeleteRequest<Response, Arguments extends Record<string, string | string[]> = undefined>(
    endpoint: string,
    queryStringArguments: Arguments,
  ): Promise<Response> {
    const path = this.buildBackendApiUrl(endpoint, queryStringArguments);

    return this.httpService.sendDeleteRequest<Response>(path);
  }

  public sendGetRequest<Response, Arguments extends Record<string, string | string[]> = undefined>(
    endpoint: string,
    queryStringArguments: Arguments,
  ): Promise<Response> {
    const path = this.buildBackendApiUrl(endpoint, queryStringArguments);

    return this.httpService.sendGetRequest<Response>(path);
  }

  public sendPostRequest<Input, Response, Arguments extends Record<string, string> = undefined>(
    endpoint: string,
    body: Input,
    queryStringArguments?: Arguments,
  ): Promise<Response> {
    const path = this.buildBackendApiUrl(endpoint, queryStringArguments);

    return this.httpService.sendPostRequest<Input, Response>(path, body);
  }

  public sendPostRequestWithLoading<Input, Response, Arguments extends Record<string, string> = undefined>(
    endpoint: string,
    body: Input,
    queryStringArguments?: Arguments,
  ): Promise<Response> {
    const path = this.buildBackendApiUrl(endpoint, queryStringArguments);

    return this.httpService.sendPostRequestWithLoading<Input, Response>(path, body);
  }

  public sendPutRequest<Input, Response, Arguments extends Record<string, string> = undefined>(
    endpoint: string,
    body: Input,
    queryStringArguments?: Arguments | undefined,
  ): Promise<Response> {
    const path = this.buildBackendApiUrl(endpoint, queryStringArguments);

    return this.httpService.sendPutRequest<Input, Response>(path, body);
  }

  private buildBackendApiUrl<Arguments extends Record<string, string | string[]>>(
    endpoint: string,
    queryStringArguments?: Arguments,
  ): URL {
    const backendUrl =
      localStorage.getItem("FORCE_LOCAL_DOMAIN") === "true"
        ? new URL("http://localhost:3001")
        : environment.endpoints.backendDomain;

    return new URL(`${backendUrl}${endpoint}?${objectToQueryString(queryStringArguments)}`);
  }
}
