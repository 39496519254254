export const apiPath = {
  activeSubscriptions: "active_subscriptions",
  contact: "contacts",
  history: "history",
  item: "items",
  notification: "notifications",
  orderCheckoutSession: "order_checkout_sessions",
  order: "orders",
  orderFees: "order_fees",
  passwordReset: "password_resets",
  paymentRequirement: "payment_requirements",
  priceMigration: "price_migrations",
  scan: "scans",
  setupIntent: "setup_intents",
  subscription: "subscriptions",
  subscriptionCheckoutSession: "subscription_checkout_sessions",
  subscriptionType: "subscription_types",
  userDeletionRequest: "user_deletion_requests",
  user: "users",
  warehouseOperations: "warehouse_operations",
};

export const itemHistoryEndpoint = {
  createItem: "create_item",
  updateCategory: "update_category",
  updateLocation: "update_location",
  updateStatus: "update_status",
};

export const notificationEndpoint = {
  allUsers: "all",
};

export const orderHistoryEndpoint = {
  acceptOrder: "accept_order",
  addedToCalendar: "added_to_calendar",
  assignDriver: "assign_driver",
  create_order: "create_order",
  deliveryCompleted: "delivery_completed",
  deliveryStarted: "delivery_started",
  dropAtWarehouse: "drop_at_warehouse",
  onboardingCompleted: "onboarding_completed",
  pickupCompleted: "pickup_completed",
  pickupStarted: "pickup_started",
  rejectOrder: "reject_order",
  stagingCompleted: "staging_completed",
  updateAddress: "update_address",
  updateFulfillmentDatetime: "update_fulfillment_datetime",
  updateStatus: "update_status",
  uploadPicture: "upload_picture",
};

export const orderStatusEndpoint = {
  computeStatus: "compute_status",
};

export const passwordResetEndpoint = {
  askSetNewPassword: "ask_set_new_password",
  requestLink: "request_link",
  resetPassword: "reset_password",
};

export const paymentEndpoint = {
  forPickupOnly: "pickups_only",
};

export const scanItemEndpoint = {
  destination: "destination",
  rack: "rack",
  stage: "stage",
  vehicle: "vehicle",
};

export const scanOrderEndpoint = {
  stage: "stage",
  vehicle: "vehicle",
};

export const warehouseOperationsEndpoint = {
  completeDropToWarehouse: "complete_drop_to_warehouse",
  completeMoveToVehicle: "complete_move_to_vehicle",
  completeOnboarding: "complete_onboarding",
  completeStaging: "complete_staging",
};
